@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "AnekLatin";
  src: local("AnekLatin"),
    url("./fonts/AnekLatin/AnekLatin_SemiExpanded-SemiBold.ttf")
      format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "AnekLatin";
  src: local("AnekLatin"),
    url("./fonts/AnekLatin/AnekLatin_SemiExpanded-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "AnekLatin";
  src: local("AnekLatin"),
    url("./fonts/AnekLatin/AnekLatin_SemiExpanded-Regular.ttf")
      format("truetype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #fafafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
